import { useState } from "react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { ActionFunction, LoaderFunction, json, redirect } from "@vercel/remix";
import {
  Form,
  useActionData,
  useNavigation,
  useLoaderData,
  Outlet,
} from "@remix-run/react";
import { createSupabaseServerClient } from "@figment-fitness/database/supabase";
import { getRedirectTo } from "../utils/redirect";
import {
  Box,
  Container,
  Flex,
  Button,
  TextField,
  RadixForm,
  Text,
} from "@figment-fitness/ui";
import {
  FormInput,
  FormCheckbox,
  FormTextarea,
} from "@figment-fitness/ui/components/form-input";
import { useForm, getFormProps } from "@conform-to/react";
import { TitleBodyContainer } from "@figment-fitness/ui/components/title-body-container";
import { SetInitialPasswordReqSchema } from "~/schema/fitness/user";
import { CenterContentLayout } from "@figment-fitness/ui/components/layout";

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: SetInitialPasswordReqSchema,
  });

  if (submission.status !== "success") {
    return json({
      success: false,
      error: "エラーが発生しました",
      submission: submission.reply(),
    });
  }

  const { supabaseClient, headers } = createSupabaseServerClient(request);

  const {
    data: { user },
    error,
  } = await supabaseClient.auth.updateUser({
    password: submission.value.newPassword,
  });

  if (error) {
    return json({
      success: false,
      error: error.message,
      submission: submission.reply(),
    });
  }

  if (!user) {
    return json({
      success: false,
      error: "ユーザーが見つかりません",
      submission: submission.reply(),
    });
  }

  return redirect(getRedirectTo(user.app_metadata), { headers });
};

// MEMO: /auth/はpublic
export default function AuthLayout() {
  return (
    <CenterContentLayout>
      <Outlet />
    </CenterContentLayout>
  );
}
